import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FullHtml from './FullHtml';
import Palette from './Palette';

const Html = () => (
<section className="section feature">
	<div className="container">
		<div className="row bg-elipse">
			<div className="col-lg-12 align-self-center text-center text-lg-right">
				<div className="feature-item">
					<Switch>
						<Route exact path='/html' component={FullHtml}/>
						<Route path='/html/:number' component={Palette}/>
					</Switch>
				</div>
			</div>
		</div>
	</div>
</section>

)

export default Html;
