import React from 'react';
import Menu from './Menu';

const Header = () => (
  <header>
    <Menu />
  </header>
)

export default Header;
