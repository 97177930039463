import React from 'react';
import * as guessColor from 'guess-color';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const palette = guessColor().getPalette();

const Palette = (props) => {
  const name = props.match.params.number;
  const [r, g, b] = palette[name];
  const color = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  if (!color) {
    return ( <div>Sorry, but color was not found</div> );
  }

  const imageBase64 = 'data:image/jpg;base64,' + guessColor().imageByName(name).toBase64();

  return (
	<div className="container">
		<Helmet
			title={name}
                meta={[
                    { "data-rh": "true", "rel": "apple-touch-icon", "sizes": "152x152", "href": imageBase64 },
                    { "data-rh": "true", "rel": "apple-touch-icon", "sizes": "120x120", "href": imageBase64 },
                    { "data-rh": "true", "rel": "apple-touch-icon", "sizes": "76x76", "href": imageBase64 },
                    { "data-rh": "true", "rel": "apple-touch-icon", "sizes": "60x60", "href": imageBase64 },

                    { "property": "og:title", "content": name },
                    { "property": "og:description", "content": name },
                    { "property": "og:url", "content": 'https://colors.click/html/' + name },
                    { "property": "og:image", "content": 'https://colors.click/html/' + name + '/example.png' },

                    { "rel": "apple-touch-icon", "sizes": "152x152", href: imageBase64 },
                    { "rel": "apple-touch-icon", "sizes": "120x120", href: imageBase64 },
                    { "rel": "apple-touch-icon", "sizes": "76x76", href: imageBase64 },
                    { "rel": "apple-touch-icon", "sizes": "60x60", href: imageBase64 },
                ]}
		/>
          <MetaTags>
            <title>{name}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={name} />
            <meta property="og:description" content={name} />
            <meta property="og:url" content={'https://colors.click/html/' + name} />
            <meta property="og:image" content={'https://colors.click/html/' + name + '/example.png'} />
          </MetaTags>
		<div className="row">
			<div className="col-12">
				<div className="section-title">
					<h2>{name}</h2>
					<img role="presentation" alt={name} width={300} src={imageBase64} />
				</div>
				<div className="section-title">
					<h4>{color}</h4>
				</div>
			</div>
			<div className="col-12">
				<Link className="nav-link" to='/html'><i className="fa fa-angle-double-left"></i> back to the full list of colors</Link>
			</div>
		</div>
	</div>
  )
}

export default Palette;
