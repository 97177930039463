import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header, Footer, Home, Html } from './components'

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route path='/html' component={Html}/>
    </Switch>
  </main>
)

//const Main2 = () => ( <b>Hello World!</b> )

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
