import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

//ReactDOM.render((
ReactDOM.hydrate((
  <BrowserRouter>
    <ScrollMemory />
    <App />
  </BrowserRouter>
), document.getElementById('root'));

