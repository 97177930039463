import React from 'react';

const Footer = () => (
<footer className="footer-main">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 mr-auto">
          <div className="footer-logo">
            <a href="/" className="brandv">COLORS<br />.click</a>
          </div>
        </div>
        <div className="col-lg-4 mr-auto">
          <div className="copyright">
            <p>@2018-2019 ivanoff All Rights Reserved</p>
          </div>
        </div>
        <div className="col-lg-4 text-lg-right">
          <ul className="social-icons list-inline">
            <li className="list-inline-item">
              <a href="/"><i className="fa fa-facebook-square"></i></a>
            </li>
            <li className="list-inline-item">
              <a href="/"><i className="fa fa-twitter-square"></i></a>
            </li>
            <li className="list-inline-item">
              <a href="/"><i className="fa fa-linkedin-square"></i></a>
            </li>
            <li className="list-inline-item">
              <a href="/"><i className="fa fa-github-square"></i></a>
            </li>
          </ul>
          <ul className="footer-links list-inline">
            <li className="list-inline-item">
              <a href="/#">ABOUT</a>
            </li>
            <li className="list-inline-item">
              <a href="/#team">TEAM</a>
            </li>
            <li className="list-inline-item">
              <a href="/#">CONTACT</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
</footer>
)

export default Footer;
