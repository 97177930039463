import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
//  UncontrolledDropdown,
//  DropdownToggle,
//  DropdownMenu,
//  DropdownItem
} from 'reactstrap';

// example: https://reactstrap.github.io/components/navbar/
export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
        <Navbar light expand="md" className="navbar main-nav fixed-top navbar-expand-lg">
          <NavbarBrand href="/">COLORS<br />.click</NavbarBrand>
          <NavbarToggler className="mr-2" onClick={this.toggle}><i className="fa fa-bars" style={{color:'#fff', fontSize: '28px'}}></i></NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://play.google.com/store/apps/details?id=com.getcolors" target="_blank">Screenshots</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/html">HTML Colors</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/">Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}
