import React from 'react';
import { Link } from 'react-router-dom';
import guessColor from 'guess-color';

const palette = guessColor().getPalette();
const colorNames = Object.keys(palette);
let colorCodes = {};

colorNames.map(name => {
  const [r, g, b] = palette[name];
  const webColor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  colorCodes[name] = webColor;
  return true;
})


const FullHtml = () => (
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h2>HTML colors</h2>
					<p>Demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee idea of denouncing pleasure and praising</p>
				</div>
			</div>
		</div>
      {
        colorNames.map(name => (
			<div class="row">
				<div class="col-lg-3">
					<Link class="color-text" to={`/html/${name}`}>{name}</Link>
					<br />
					<Link to={`/html/${name}`}>{colorCodes[name]}</Link>
				</div>
				<div class="col-lg-9">
					<Link to={`/html/${name}`}>
						<div class="col-lg-9 color-color" style={{backgroundColor: colorCodes[name]}}></div>
					</Link>
				</div>
			</div>
        ))
      }
  </div>
)

export default FullHtml;
