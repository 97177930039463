import React from 'react';

const Home = () => (
<main>
<section class="banner bg-1">
	<div class="container">
		<div class="row">
			<div class="col-md-8 align-self-center">
				<div class="content-block">
					<h1>Amazing App Best for design</h1>
					<h5>Get color from everything in your life with a simple way</h5>
					<div class="app-badge">
						<ul class="list-inline">
							<li class="list-inline-item">
								<a href="https://play.google.com/store/apps/details?id=com.getcolors"><img class="img-fluid" src="images/google-play.png" alt="google-play" /></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="image-block">
					<img class="img-fluid" src="images/iphone-banner.png" alt="iphone-banner" />
				</div>
			</div>
		</div>
	</div>
</section>

<section class="about section bg-2" id="about">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 mr-auto">
				<div class="image-block">
					<img src="images/iphone-feature.png" alt="iphone-feature" class="img-fluid" />
				</div>
			</div>
			<div class="col-lg-6 col-md-10 m-md-auto align-self-center ml-auto">
				<div class="about-block">
					<div class="about-item">
						<div class="icon">
							<i class="fa fa-heart"></i>
						</div>
						<div class="content">
							<h5>Creative Design</h5>
							<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete accounta</p>
						</div>
					</div>
					<div class="about-item active">
						<div class="icon">
							<i class="fa fa-globe"></i>
						</div>
						<div class="content">
							<h5>Easy to Use</h5>
							<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete accounta</p>
						</div>
					</div>
					<div class="about-item">
						<div class="icon">
							<i class="fa fa-compass"></i>
						</div>
						<div class="content">
							<h5>Best User Experience</h5>
							<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete accounta</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section counter bg-gray">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-md-6">
				<div class="counter-item">
					<h3>500+</h3>
					<p>Downloads</p>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-item">
					<h3>13</h3>
					<p>Reviews</p>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-item">
					<h3>5.0★</h3>
					<p>Rating</p>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-item">
					<h3>3+</h3>
					<p>Rated</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section team bg-shape-two" id="team">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h2>Our Creative Team</h2>
					<p>Demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee idea of denouncing pleasure and praising</p>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6">
			</div>
			<div class="col-lg-4 col-md-6">
<div class="team-member text-center">
	<div class="image">
		<img class="img-fluid" src="images/member-one.jpg" alt="team-member" />
	</div>
	<div class="name">
		<h5>Dimitry Ivanov</h5>
	</div>
	<div class="position">
		<p>Product Owner</p>
	</div>
	<div class="skill-bar">
		<div class="progress" data-percent="85%">
		  	<div class="progress-bar"></div>
		</div>
		<span>85%</span>
	</div>
</div>
			</div>
			<div class="col-lg-4 col-md-6">
			</div>
		</div>
	</div>
</section>

</main>

)

export default Home;
